import React, { Component } from "react";
import danielsLogo from "../../images/DanielBlack.png";
// import Illustration from "../../images/Illustration.png";
import "./component.storeNumber.css";
import * as authActions from '../../redux/actions/authActions';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as storeactions from '../../redux/actions/storeNumber.actions';
import * as adminActions from '../../redux/actions/admin.actions';
import Loader from "react-loader-spinner";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import ConfirmDialog from "../../components/component.confirmDialog";
import * as applyNowActions from '../../redux/actions/applyNow.actions';
import { trackPromise } from "react-promise-tracker";
import axios from 'axios';

class StoreNumber extends Component {
    constructor() {  
        super();
        this.state = {  
          show: false,
          accessModal: false,
          showSpinner: false,
          userStatus:false,
          showAlert:false,
          alertMsg:"", 
          ipAddress:"",
          isAssociateStore_IP:false,
          imgFile : "",
          dailyScenario : {
            isImage : false,
            isText : false,
            dailyNote : "",
          }
        }  
      } 

      async componentDidMount() {
        await axios.get('/appsettings.json').then(async (response) =>  {
          let appsettings = response.data;
          localStorage.setItem('appsettings', JSON.stringify(appsettings));
        });
        await trackPromise(
            this.props.actionStore.getText_Img().then(res => {
                if(res) {
                    res.dailyNote = res.dailyNote?.replaceAll("*^*","'");
                    if(res.isImage && res.isText) {
                        this.setState({dailyScenario : {...this.state.dailyScenario, isImage : res.isImage, isText : res.isText, dailyNote : res.dailyNote}});
                        this.loadImg(res.imgFileName);
                    }
                    else if(res.isText) {
                        this.setState({dailyScenario : {...this.state.dailyScenario, isText : res.isText, dailyNote : res.dailyNote}});
                    }
                    else if(res.isImage) {
                        this.setState({dailyScenario : {...this.state.dailyScenario, isImage : res.isImage}});
                        this.loadImg(res.imgFileName);
                    }
                }
            })
            .catch(err => {
                APIFailedMessage.displayError(err);
            })
        );        
      }

    loadImg(imgFileName) {
        trackPromise(
            this.props.actionStore.getImageFileFromBlob(imgFileName).then(res => {
                if(res.blobUrl !== undefined) {
                    this.setState({imgFile : res.blobUrl});
                }
            })
            .catch(err => APIFailedMessage.displayError(err))
        )
    }
    
    handleModal = () => {  
        this.setState({ show: false, showSpinner: false });
        window.location.reload();
    } 
    handleAccessModal = () => {
        this.setState({ accessModal: false, showSpinner: false,userStatus:false,showAlert:false });
        window.location.reload();
    }
    handleAlertModal = () => {
        let showAlert=!this.state.showAlert;
        this.setState({showAlert:showAlert});
        if(!showAlert){
            this.props.history.push("/customer-lookup");
        }
    }  
    associateLogin = async () => {
        try {
            let res = await this.props.actions.login();
             if (res) {
                this.setState({ showSpinner: true })
                 localStorage.setItem('loggedUserName', res.idToken.name);
                 localStorage.setItem('loggedUserEmail', res.account.userName);
                 let associateId= res.account.userName;
                let searchFilter = {
                    Filter1_AssociateEmail: res.account.userName,
                    Filter2_AssociateObject: res.idToken.objectId
                }
                await this.props.actionStore.getStoreDetails(searchFilter).then(
                     async response => {
                    if(response.status.toLowerCase()==="active"){
                        let access = response.portalAccessAllowed?.split(",");
                        let url = window.location.href.split("-")[1];
                        let statingURL = window.location.href.split('/')[2];
                        let productionURL = window.location.href.split('/')[2];
                        let localhost = window.location.href.slice(7, 16);
                        let prodEnvAccess = access?.find((val) => { return val.toLowerCase() === "prod" });
                        let stageEnvAccess = access?.find((val) => { return val.toLowerCase() === "stage" });
                        access = access?.filter((val) => { return val.toLowerCase() === url?.toLowerCase() });
                        if ((access.length > 0 && access !== undefined) || (localhost === "localhost") ||
                             (prodEnvAccess === "Prod" && productionURL === "storeportal.danielsjewelers.com") || 
                             (stageEnvAccess === "Stage" && statingURL === "storeportalstaging.danielsjewelers.com")) {
                            if (response.stores.data.length > 0 && response.stores.data[0].isActive) {
                                localStorage.setItem('hasAdminAccess', JSON.stringify(response.hasAdminAccess));
                                localStorage.setItem('hasPortalAccess', JSON.stringify(response.hasPortalAccess));
                                sessionStorage.setItem("havingFortivaInsurance" , response.stores.data[0].isFortivaInsurance);
                                sessionStorage.setItem("havingDanielInsurance", response.stores.data[0].isDanielsInsurnace);
                                localStorage.setItem('role_name', response.roleName);
                                sessionStorage.setItem('isLoggedIn', true);
                                sessionStorage.setItem("thirdPartyLink", "false");
                                localStorage.setItem("token", response.token);
                               await this.props.adminActions.getSignOutTime()
                                .then(async res => {
                                    if (res) {
                                        this.setState({ signOutTime: res.signOutInMins,isAssociateStore_IP:res.isAssociateStore_IP });
                                        localStorage.setItem("signOutInMins", res.signOutInMins);
                                        if(res.isAssociateStore_IP){
                                            let ipaddress=  await this.props.applyNowActions.getIpAddressNew();
                                            if(ipaddress){
                                                sessionStorage.setItem("ipAddress", ipaddress);
                                                let associateStore = await this.props.applyNowActions.saveIpAddress(ipaddress, associateId);
                                                if (associateStore) {
                                                    let storeDetails = await this.props.adminActions.getstoredetailsById(JSON.parse(associateStore));
                                                    if(storeDetails){
                                                        sessionStorage.setItem("AssociateState",storeDetails[0]?.address2?.split(",")[1]?.trim());
                                                    }
                                                    else{
                                                        sessionStorage.setItem("AssociateState",response?.stores?.data[0]?.address2?.split(",")[1]?.trim());
                                                    }
                                                    this.setState({ipAddress:associateStore});
                                                    localStorage.setItem('associateStores', JSON.stringify(associateStore));
                                                }
                                                else{
                                                    this.setState({ show: true });
                                                    // localStorage.setItem('associateStores', JSON.stringify(response.stores.data[0].code));
                                                    //  sessionStorage.setItem("AssociateState",response?.stores?.data[0]?.address2?.split(",")[1]?.trim());
                                               }   
                                            }
                                        }
                                        else{
                                             localStorage.setItem('associateStores', JSON.stringify(response.stores.data[0].code));
                                              sessionStorage.setItem("AssociateState",response?.stores?.data[0]?.address2?.split(",")[1]?.trim());
                                        }
                                    }
                                })
                                .catch(error => {
                                    APIFailedMessage.displayError(error);
                                });
                                
                                let obj={
                                    associateId: res.account.userName
                                  }
                                 await this.props.actionStore.getUserLoginCount(obj)
                                .then(async response=>{
                                         this.props.actionStore.userLoginDetails(obj);
                                        if(response<3){
                                             this.props.adminActions.getAlertMsg().then(
                                                async response => {
                                                    if(response) {
                                                        if(response.alertMsg !== "\n" && response.alertMsg!==null  && response.alertMsg!=="") {
                                                        this.setState({alertMsg: response.alertMsg,showAlert:true});
                                                        }
                                                    }
                                                    this.redirectToLookup();
                                              }).catch(err => {
                                                  APIFailedMessage.displayError(err);
                                              });   
                                        }
                                        else{
                                             this.redirectToLookup();
                                        }
                                })
                            }
                            else {
                                this.setState({ show: true });
                            }
                        }
                        else {
                            this.setState({ accessModal: true });
                        }
                    }
                    else{
                        this.setState({userStatus:true});
                    }
                    }
                )
            }
        }
       catch (error) {
        APIFailedMessage.displayError(error);
       }
      }
     redirectToLookup() {
        if (!this.state.show && !this.state.accessModal && !this.state.userStatus && !this.state.showAlert) {
            if(!this.state.isAssociateStore_IP){
                this.props.adminActions.getSignOutTime()
                .then(res => {
                    if (res) {
                        this.setState({ signOutTime: res.signOutInMins });
                        localStorage.setItem("signOutInMins", res.signOutInMins);
                    }
                })
                .catch(error => {
                    APIFailedMessage.displayError(error);
                });
            }
             this.props.history.push("/customer-lookup");
    }
}
  render() {
    let style = "";
    let dailyNoteStyle = "";
    if(this.state.dailyScenario.isImage && this.state.dailyScenario.isText) {
        if(this.state.dailyScenario.dailyNote?.split("\n")?.length <=4 || this.state.dailyScenario.dailyNote?.split("\n")?.length === undefined) {
            if(this.state.dailyScenario.dailyNote?.split(" ")?.length <= 50) {
                style = "Illustration3";
                dailyNoteStyle = "dailyStyle3";
            }
            else {
                style = "Illustration2";
                dailyNoteStyle = "dailyStyle2"
            }
        }
        else {
            style = "Illustration2";
            dailyNoteStyle = "dailyStyle2"
        }
    }
    else { 
        style = "Illustration1";
        dailyNoteStyle = "dailyStyle1";
    }

      return (
        <>
       {this.state.showSpinner && (
            <div
                style={{
                    top: "25vh",
                    left: "25%",
                    justifyContent: "center",
                    alignItems: "center",
                    position: " absolute",
                    zIndex: "1000",
                    minHeight: "50vh",
                    bottom: "25vh",
                    display: "flex",
                    right: "25%",
                    minWidth: " 50%",
                }}
            >
                <Loader type="ThreeDots" color="#5C068C" height="100" width="100" />
            </div>
        )}
          <div className="container-fluid">
            
              <div className="row">
              <div className="col-xl-6 d-none d-xl-block p-0">
                <div className="d-flex flex-column h-100">
                    {this.state.dailyScenario.isImage && 
                        <img
                            id={style}
                            src={this.state.imgFile}
                            alt="Illustration"
                            className="img-fluid my-auto"
                            style={{}}
                        />
                    }
                    {(this.state.dailyScenario.isText && this.state.dailyScenario.isImage) &&
                        <div id={dailyNoteStyle} className="overflow-auto">
                            <h5 className="m-0 mx-auto my-auto p-4" style={{whiteSpace : "pre-wrap"}}>{this.state.dailyScenario.dailyNote}</h5>
                        </div>
                    }
                    {(this.state.dailyScenario.isText && !this.state.dailyScenario.isImage) &&
                        <div id={dailyNoteStyle} className="overflow-auto">
                            <h3 className="m-0 mx-auto my-auto p-4" style={{whiteSpace : "pre-wrap"}}>{this.state.dailyScenario.dailyNote}</h3>
                        </div>
                    }
                </div>
                </div>
                  <div className="col-lg-12 col-xl-6 d-flex min-vh-100 flex-column bg-white p-0">
                    <div className="d-lg-none d-xl-none overflow-auto bg-primary" style={{maxHeight: "150px"}}><p className="text-center mb-0 pt-1 pb-1 pr-2 pl-2" style={{whiteSpace : "pre-wrap"}}>{this.state.dailyScenario.dailyNote}</p></div>
                      <div className="text-center mt-5">
                          <div className="mt-5 mt-md-0 mt-sm-0 mt-lg-0">
                              <img src={danielsLogo} alt="Daniels Logo" width="172px"></img>
                          </div>
                      </div>
                      <div className="flex-fill fill d-flex justify-content-center align-items-center">
                          <div id="associateLoginBtn">
                              <button
                                  className="btn text-white w-100 pt-2 pb-2"
                                  onClick={() => {
                                      this.associateLogin();
                                  }}
                              >
                                  Associate Login
                              </button>
                          </div>

                      </div>
                  </div>
              </div>
            <ConfirmDialog
                show={this.state.show}
                handleModalPopup={this.handleModal}
                message={"<p class='mb-0'>No stores assigned for " + (localStorage.getItem("loggedUserName")) + ". Please contact administrator.</p>"}
                header_name="Warning!!"
                button2_name="OK"
                button2={this.handleModal}
            />

            <ConfirmDialog
                show={this.state.accessModal}
                handleModalPopup={this.handleAccessModal}
                message={"<p class='mb-0'>Access is denied, please contact administrator.</p>"}
                header_name="Warning!!"
                button2_name="Close"
                button2={this.handleAccessModal}
            />
            <ConfirmDialog
                show={this.state.userStatus}
                handleModalPopup={this.handleAccessModal}
                message={"<p class='mb-0'>User is inActive and can't access the portal, please contact administrator.</p>"}
                header_name = "Warning!!"
                button2_name="Close"
                button2={this.handleAccessModal}
            />
            <ConfirmDialog
                show={this.state.showAlert}
                handleModalPopup={this.handleAlertModal}
                message={`<span class=\"mb-0 alertMsg \">${this.state.alertMsg}</span>`}
                header_name = "Alert!!"
                button2_name="Close"
                button2={this.handleAlertModal}
            />
          </div>
          </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
    return {
     actions: bindActionCreators(authActions, dispatch),
     actionStore: bindActionCreators(storeactions, dispatch),
     adminActions: bindActionCreators(adminActions, dispatch),
     applyNowActions: bindActionCreators(applyNowActions, dispatch),
    }
         
   }
export default connect(null, mapDispatchToProps)(StoreNumber);